import { Row } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import { RowProduct } from '..'
import { HomeProductsListQuery } from '../../../graphql/HomeProducts.gql'
import { RowProductThreeFragment } from './RowProductThree.gql'

// @todo HomeProductsListQuery should not be used here, this should be more generic
export function RowProductThree(props: HomeProductsListQuery & RowProductThreeFragment) {
  const { productBlocks, firstRowProduct, secondRowProduct, thirdRowProduct } = props

  if (!productBlocks?.items || !firstRowProduct || !secondRowProduct || !thirdRowProduct)
    return null

  // This fix must be applied because the query from HomeProducts.graphql (HomeProductsListQuery) does not return the desired order.
  // See todo above, this should be more generic.
  const firstRowProductItems = productBlocks.items[1]
  const secondRowProductItems = productBlocks.items[0]
  const thirdRowProductItems = productBlocks.items[2]

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.primary.light,
        p: `${theme.spacings.lg} 0`,
      })}
    >
      <Row
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: theme.spacings.sm,
          rowGap: theme.spacings.xl,
          mb: 0,
          columnGap: { lg: theme.spacings.xl },
          [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
            pr: 0,
          },
          '& > form:nth-last-of-type(1)': {
            gridColumn: '1 / 3',
            [theme.breakpoints.down('md')]: {
              gridColumn: 'unset',
            },
          },
          '& .MuiTypography-root strong, & .MuiButton-cutRight': { whiteSpace: 'nowrap' },
          '& .MuiButton-cutRight': { maxWidth: '205px' },
        })}
      >
        <RowProduct
          identity={firstRowProduct.identity}
          productUrlKeys={[]}
          key={firstRowProductItems?.uid}
          title={firstRowProduct.title}
          pageLinks={firstRowProduct.pageLinks}
          productCopy={firstRowProduct.productCopy}
          items={firstRowProductItems?.products?.items}
          variant='Swipeable'
          {...firstRowProductItems?.products?.items?.[0]}
        />

        <RowProduct
          identity={secondRowProduct.identity}
          productUrlKeys={[]}
          key={secondRowProductItems?.uid}
          title={secondRowProduct.title}
          pageLinks={secondRowProduct.pageLinks}
          productCopy={secondRowProduct.productCopy}
          items={secondRowProductItems?.products?.items}
          variant='Swipeable'
          {...secondRowProductItems?.products?.items?.[0]}
        />

        <RowProduct
          identity={thirdRowProduct.identity}
          productUrlKeys={[]}
          key={thirdRowProductItems?.uid}
          title={thirdRowProduct.title}
          pageLinks={thirdRowProduct.pageLinks}
          productCopy={thirdRowProduct.productCopy}
          items={thirdRowProductItems?.products?.items}
          variant='Swipeable'
          {...thirdRowProductItems?.products?.items?.[0]}
        />
      </Row>
    </Box>
  )
}
