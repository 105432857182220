import { PageOptions } from '@graphcommerce/framer-next-pages'
import { HygraphPagesQuery, hygraphPageContent } from '@graphcommerce/graphcms-ui'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { GetStaticProps, MetaRobots, PageMeta } from '@graphcommerce/next-ui'
import { LayoutNavigation, LayoutNavigationProps, RowRenderer } from '../components'
import { RowProductThree } from '../components/GraphCMS/RowProductThree/RowProductThree'
import { LayoutDocument } from '../components/Layout/Layout.gql'
import { HomeProductsListDocument, HomeProductsListQuery } from '../graphql/HomeProducts.gql'
import { graphqlSharedClient, graphqlSsrClient } from '../lib/graphql/graphqlSsrClient'

type Props = HygraphPagesQuery & HomeProductsListQuery
type RouteProps = { url: string }
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, Props, RouteProps>

function CmsPage(props: Props) {
  const { pages, productBlocks } = props
  const page = pages?.[0]
  const product = productBlocks?.items?.[0]

  return (
    <>
      <PageMeta
        title={page?.metaTitle ?? page?.title ?? ''}
        metaDescription={page?.metaDescription ?? ''}
        metaRobots={page?.metaRobots.toLowerCase().split('_') as MetaRobots[] | undefined}
        canonical='/'
      />

      {pages?.[0] && (
        <RowRenderer
          content={pages?.[0].content}
          renderer={{
            RowProductThree: (rowProps) => (
              <RowProductThree {...rowProps} {...product} productBlocks={productBlocks} />
            ),
          }}
        />
      )}
    </>
  )
}

CmsPage.pageOptions = {
  Layout: LayoutNavigation,
} as PageOptions

export default CmsPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)

  const conf = client.query({ query: StoreConfigDocument })
  const page = hygraphPageContent(staticClient, 'page/home')
  const layout = staticClient.query({ query: LayoutDocument, fetchPolicy: 'cache-first' })

  if (!(await page).data.pages?.[0]) return { notFound: true }

  const productBlocks = staticClient.query({
    query: HomeProductsListDocument,
  })

  return {
    props: {
      ...(await page).data,
      ...(await layout).data,
      ...(await productBlocks).data,
      up: { href: '/', title: 'Home' },
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 60 * 20,
  }
}
